app.controller('shipmentController', function shipmentController($scope, $http, $cookies, $window, $location, $rootScope, $filter, $routeParams, $sce) {
    $rootScope.selectedMenu = 'viewShipment';
    $rootScope.pageTitle = 'View Shipment';
    $scope.shipmentId = $routeParams.id;
    $scope.shipment = {};

    if (!$scope.shipmentId || isNaN($scope.shipmentId)) {
        window.location.href = '#!/shipments';
    }

    $http({
        method: 'GET',
        url: $rootScope.apiPath + '/shipments/getShipment/' + $scope.shipmentId,
        headers: { 'X-Auth-Token': $rootScope.authToken }
    }).then(function successCallback(response) {
        $scope.shipment = response.data;
        $rootScope.pageTitle = $scope.shipment.reference;
    }, function errorCallback(response) {
        console.log(response);
    });
});
