var app = angular.module('app', ["ngCookies", "ngRoute"]);

var version = 12.1;
var _version = Date.now();

app.run(function ($http, $cookies, $rootScope, $location) {
    $rootScope.pageTitle = 'Dashboard';

    $rootScope.authToken = Cookies.get('authorizationToken');
    $rootScope.apiPath = 'https://localhost/seller-center/public/seller';
    $rootScope.user = null;
    if (!$rootScope.authToken) {
        window.location.href = 'login.html';
    } else {
        $http({
            method: 'GET',
            url: $rootScope.apiPath + "/getUser",
            headers: { 'X-Auth-Token': $rootScope.authToken }
        }).then(function successCallback(response) {
            if (response.data && !response.data.error) {
                $rootScope.user = response.data.user;
            } else {
                window.location.href = 'login.html';
            }
        }, function errorCallback(response) {
            window.location.href = 'login.html';
        });
    }

    $('.logoutBtn').on('click', function () {
        Cookies.remove('authorizationToken');
        window.location.href = 'login.html';
    });
});

/* app.config(['$cookiesProvider', function ($cookiesProvider) {
    $cookiesProvider.defaults.path = '/';
    // $cookiesProvider.defaults.domain = cookieDomain;
}]);

app.factory('timeoutHttpIntercept', function ($rootScope, $q, $timeout) {
    return {
        responseError: function (rejection) {
            if (rejection.status) {
                switch (rejection.status) {
                    case 404:
                        toastr["error"]("Resource not found");
                        break;
                    case 500:
                    case 501:
                    case 504:
                        toastr["error"]("Internal server error");
                        break;
                    default:
                        toastr["error"]("There is problem with data");
                        break;
                }
            }
            return $q.reject(rejection);
        }
    };
});

app.config(function ($httpProvider) {
    $httpProvider.interceptors.push('timeoutHttpIntercept');
}); */