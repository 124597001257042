app.controller('productController', function productController($scope, $http, $cookies, $window, $location, $rootScope, $filter, $routeParams, $sce) {
    $rootScope.selectedMenu = 'viewProducts';
    $rootScope.pageTitle = 'View Product';
    $scope.productId = $routeParams.id;
    $scope.selectedVariant = null;

    if (!$scope.productId || isNaN($scope.productId)) {
        window.location.href = '#!/products';
    }

    $http({
        method: 'GET',
        url: $rootScope.apiPath + '/products/getProduct/' + $scope.productId,
        headers: { 'X-Auth-Token': $rootScope.authToken }
    }).then(function successCallback(response) {
        $scope.product = response.data;
        $rootScope.pageTitle = $scope.product.name;
        $scope.product.description = $sce.trustAsHtml($scope.product.description);
    }, function errorCallback(response) {
        console.log(response);
    });

    $scope.editProduct = function () {
        $('.editProduct').modal('show');
    }

    $scope.editVariant = function (variant) {
        $scope.selectedVariant = variant;
        console.log(variant, $scope.selectedVariant);
        $('.editVariant').modal('show');
    }

    var quill = new Quill('.product-description-field', {
        theme: 'snow',
        modules: {
            'toolbar': [[{ 'size': [] }], ['bold', 'italic', 'underline', 'strike'], [{ 'color': [] }, { 'background': [] }], [{ 'header': [false, 1, 2, 3, 4, 5, 6] }, 'blockquote'], [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }], ['direction', { 'align': [] }], ['link'], ['clean']]
        },
    });

    setTimeout(function () {
        quill.root.innerHTML = '';
        quill.clipboard.dangerouslyPasteHTML(0, $scope.product.description + '<br>');
    }, 1000);
});
