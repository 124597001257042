app.controller('ordersController', function ordersController($scope, $http, $cookies, $window, $location, $rootScope, $filter, $compile, $route) {
    $rootScope.pageTitle = 'Orders';
    $rootScope.selectedMenu = 'viewOrders';
    $scope.selectedOrderProducts = [];
    $scope.statusFilter = '0';
    $scope.ordersDt = null;
    $scope.cancellationReason = '';

    $scope.loadDt = function () {
        $scope.ordersDt = $('#orders-table').DataTable({
            ajax: {
                'url': $rootScope.apiPath + '/orders/getOrders?statusFilter=' + $scope.statusFilter,
                'type': 'GET',
                beforeSend: function (request) {
                    request.setRequestHeader("X-Auth-Token", $rootScope.authToken);
                }
            },
            rowCallback: function (row) {
                if (!row.compiled) {
                    $compile(angular.element(row))($scope);
                    row.compiled = true;
                }
            },
            processing: true,
            serverSide: true,
            columns: [
                {
                    data: 'id',
                    searchable: false,
                    orderable: false,
                    render: function (data, type, row) {
                        var checkboxHTML = '<div class="form-check"> \
                                            <input type="checkbox" class="form-check-input" ng-click="selectOrderProduct(' + data + ')"> \
                                            <label class="form-check-label">&nbsp;</label> \
                                        </div>';
                        return checkboxHTML;
                    }
                },
                {
                    'data': 'product_image',
                    searchable: false,
                    orderable: false,
                    render: function (data, type, row) {
                        if (!data)
                            data = 'assets/images/product.png';
                        var html = '<div style="width:64px;"> \
                                        <img src="' + data + '" alt="product-img" title="product-img" class="rounded-circle avatar-lg"></img> \
                                    </div>';
                        return html;
                    }
                },
                {
                    'data': 'sku',
                    'name': 'order_products.sku',
                    searchable: true,
                    orderable: false,
                    visible: false
                },
                {
                    'data': 'name',
                    searchable: true,
                    orderable: true,
                    render: function (data, type, row) {
                        var html = '<div class="text-wrap" style="max-width: 200px;"><div>' + data + '</div><a href="#!/product/' + row.id + '"><small>' + (row.sku || '-') + '</small></a></div>';
                        return html;
                    }
                },
                {
                    'data': 'order_no',
                    'name': 'orders.name',
                    searchable: true,
                    orderable: true

                },
                {
                    'data': 'shopify_created',
                    name: 'orders.shopify_created',
                    searchable: false,
                    orderable: true,
                    render: function (data, type, row) {
                        return moment(data, 'YYYY-MM-DD hh:mm:ss').format('YYYY-MM-DD');
                    }
                },
                {
                    'data': 'expected_date',
                    searchable: false,
                    orderable: true,
                    render: function (data, type, row) {
                        return moment(data, 'YYYY-MM-DD hh:mm:ss').format('YYYY-MM-DD');
                    }
                },
                {
                    'data': 'quantity',
                    searchable: false,
                    orderable: true,
                    render: function (data, type, row) {
                        return $filter('number')(data, 0) + (data > 1 ? ' Units' : ' Unit');
                    }
                },
                {
                    'data': 'cost',
                    searchable: false,
                    orderable: true,
                    render: function (data, type, row) {
                        return 'PKR ' + $filter('number')(data, 2);
                    }
                },
                {
                    'data': 'price',
                    searchable: false,
                    orderable: true,
                    render: function (data, type, row) {
                        return 'PKR ' + $filter('number')(data, 2);
                    }
                },
                {
                    'data': 'discount_per_item',
                    searchable: false,
                    orderable: true,
                    render: function (data, type, row) {
                        return 'PKR ' + $filter('number')(data, 2);
                    }
                },
                {
                    'data': 'line_total',
                    searchable: false,
                    orderable: true,
                    render: function (data, type, row) {
                        return 'PKR ' + $filter('number')(data, 2);
                    }
                },
                {
                    'data': 'status',
                    'name': 'order_products.status',
                    searchable: false,
                    orderable: true,
                    render: function (data, type, row) {
                        return '<span style="font-size: 12px;" class="p-1 badge rounded-pill bg-' + row.statusDetails.color + '" >' + row.statusDetails.text + '</span>';
                    }
                }
            ],
            order: [[1, 'asc']],
            "language": {
                "paginate": {
                    "previous": "<i class='mdi mdi-chevron-left'>",
                    "next": "<i class='mdi mdi-chevron-right'>"
                }
            },
            "drawCallback": function () {
                $('.dataTables_paginate > .pagination').addClass('pagination-rounded');
            }
        });

        setTimeout(function () {
            // $('#products-table tbody').on('click', 'tr', function () {
            //     var data = $scope.productsDt.row(this).data();
            //     window.location.href = '#!/product/' + data.id;
            // });
        }, 500);

        $(".dataTables_length select").addClass('form-select form-select-sm');
        $(".dataTables_length select").removeClass('custom-select custom-select-sm');
        $(".dataTables_length label").addClass('form-label');
    }

    $scope.$watch('statusFilter', function () {
        if ($scope.ordersDt)
            $scope.ordersDt.destroy();
        $scope.loadDt();
    });

    $scope.selectOrderProduct = function (orderId) {
        $scope.selectedOrderProducts.push(orderId);
    }

    $scope.cancelOrders = function () {
        if (!$scope.selectedOrderProducts.length) {
            $.NotificationApp.send("Error", "Please select at least one order to cancel", 'top-right', '#f35d5d', 'danger');
        } else if (!$scope.cancellationReason) {
            $.NotificationApp.send("Error", "Please enter the cancellation reason", 'top-right', '#f35d5d', 'danger');
        } else {
            $http({
                method: 'POST',
                url: $rootScope.apiPath + "/orders/cancelOrders",
                headers: { 'X-Auth-Token': $rootScope.authToken },
                data: { 'orderProductIds': $scope.selectedOrderProducts }
            }).then(function successCallback(response) {
                if (response.data && !response.data.error) {
                    $('#cancelOrdersModal').modal('hide');
                    $('body').removeClass('modal-open');
                    $('.modal-backdrop').remove();
                    $.NotificationApp.send("Success", response.data.success, 'top-right', '#11ca6d', 'danger');
                    $scope.cancellationReason = '';
                    $route.reload();
                } else {
                    $.NotificationApp.send("Error", response.data.error, 'top-right', '#f35d5d', 'danger');
                }
            }, function errorCallback(response) {
                $.NotificationApp.send("Error", 'Something went wrong', 'top-right', '#f35d5d', 'danger');
            });
        }
    }

    $scope.shipOrders = function () {
        if (!$scope.selectedOrderProducts.length) {
            $.NotificationApp.send("Error", "Please select at least one order to mark as ready to ship", 'top-right', '#f35d5d', 'danger');
        } else {
            $http({
                method: 'POST',
                url: $rootScope.apiPath + "/orders/createShipment",
                headers: { 'X-Auth-Token': $rootScope.authToken },
                data: { 'orderProductIds': $scope.selectedOrderProducts }
            }).then(function successCallback(response) {
                if (response.data && !response.data.error) {
                    $('#readyToShipModal').modal('hide');
                    $('body').removeClass('modal-open');
                    $('.modal-backdrop').remove();
                    $.NotificationApp.send("Success", response.data.success, 'top-right', '#11ca6d', 'danger');
                    window.location.href = "#!/shipment/" + response.data.shipmentId;
                } else {
                    $.NotificationApp.send("Error", response.data.error, 'top-right', '#f35d5d', 'danger');
                }
            }, function errorCallback(response) {
                $.NotificationApp.send("Error", 'Something went wrong', 'top-right', '#f35d5d', 'danger');
            });
        }
    }
});