app.controller('addProductController', function addProductController($scope, $http, $cookies, $window, $location, $rootScope, $filter) {
    $rootScope.selectedMenu = 'addProduct';
    $rootScope.pageTitle = 'Add Product';
    $scope.hasOptions = false;
    $scope.hasVariants = true;
    $scope.maxOptions = 3;
    $scope.variants = [];

    $scope.options = [
        {
            'name': '',
            'values': ['']
        }
    ];

    $scope.addOption = function () {
        if ($scope.options.length < $scope.maxOptions) {
            $scope.options.push({ 'name': '', 'values': [''] });
        }
    }

    $scope.deleteOption = function (index) {
        $scope.options.splice(index);
        if (!$scope.options.length) {
            $scope.addOption(0);
        }
    }

    $scope.addOptionValue = function (optionIndex, index) {
        if ($scope.options[optionIndex].values[index] && $scope.options[optionIndex].values.length == (index + 1)) {
            $scope.options[optionIndex].values.push('');
        }
    }

    $scope.deleteOptionValue = function (optionIndex, index) {
        $scope.options[optionIndex].values.splice(index, 1);
        if (!$scope.options[optionIndex].values.length) {
            $scope.options[optionIndex].values.push('');
        }
    }

    $scope.$watch('options', function () {
        let optionValues = [];
        angular.forEach($scope.options, function (option, optionKey) {
            let valueSet = [];
            angular.forEach(option.values, function (value, key) {
                if (value) {
                    valueSet.push(value);
                }
            });
            if (valueSet.length)
                optionValues.push(valueSet);
        });
        if (optionValues.length) {
            variantCombinations = cartesianProduct(optionValues);
            angular.forEach(variantCombinations, function (value, key) {
                variantName = value.join(" / ");
                let duplicated = false;
                angular.forEach($scope.variants, function (variant, variantKey) {
                    if (variant.name == variantName)
                        duplicated = true;
                });
                if (!duplicated)
                    $scope.createVariant(key, variantName);
            });
        }
    }, true);

    function cartesianProduct(arr) {
        return arr.reduce(function (a, b) {
            return a.map(function (x) {
                return b.map(function (y) {
                    return x.concat([y]);
                })
            }).reduce(function (a, b) { return a.concat(b) }, [])
        }, [[]])
    }

    $scope.createVariant = function (key, name) {
        $scope.variants[key] =
        {
            'name': name,
            'sku': '',
            'barcode': '',
            'price': 0,
            'compare_at': 0,
            'bg_cost': 0,
            'bg_sku': ''
        };
    };

    var FileUpload = function () {
        this.$body = $("body")
    };


    /* Initializing */
    FileUpload.prototype.init = function () {
        // Disable auto discovery

        Dropzone.autoDiscover = false;

        $('[data-plugin="dropzone"]').each(function () {
            var actionUrl = $(this).attr('action')
            var previewContainer = $(this).data('previewsContainer');

            var opts = { url: actionUrl };
            if (previewContainer) {
                opts['previewsContainer'] = previewContainer;
            }

            var uploadPreviewTemplate = $(this).data("uploadPreviewTemplate");
            if (uploadPreviewTemplate) {
                opts['previewTemplate'] = $(uploadPreviewTemplate).html();
            }

            var dropzoneEl = $(this).dropzone(opts);

        });
    },

    //init fileupload
    $.FileUpload = new FileUpload, $.FileUpload.Constructor = FileUpload
    $.FileUpload.init()
    if ($('[data-plugins="dropify"]').length > 0) {
        // Dropify
        $('[data-plugins="dropify"]').dropify({
            messages: {
                'default': 'Drag and drop a file here or click',
                'replace': 'Drag and drop or click to replace',
                'remove': 'Remove',
                'error': 'Ooops, something wrong appended.'
            },
            error: {
                'fileSize': 'The file size is too big (1M max).'
            }
        });
    }

    // Snow theme
    var quill = new Quill('#snow-editor', {
        theme: 'snow',
        modules: {
            'toolbar': [[{ 'size': [] }], ['bold', 'italic', 'underline', 'strike'], [{ 'color': [] }, { 'background': [] }], [{ 'header': [false, 1, 2, 3, 4, 5, 6] }, 'blockquote'], [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }], ['direction', { 'align': [] }], ['link'], ['clean']]
        },
    });

    // Select2
    $('.tags-select2').select2({ tags: true });

    $('#hasVariants').on('click', function () {
        var hasVariants = $('#hasVariants').is(":checked");
        if (hasVariants) {
            $('.product-options').show();
        } else {
            $('.product-options').hide();
        }
    });
});