app.controller('productsController', function productsController($scope, $http, $cookies, $window, $location, $rootScope, $filter) {
    $rootScope.selectedMenu = 'viewProducts';
    $rootScope.pageTitle = 'Products';
    $scope.productsDt = null;
    var productsDt = $('#products-table').DataTable({
        ajax: {
            'url': $rootScope.apiPath + '/products/getProducts',
            'type': 'GET',
            beforeSend: function (request) {
                request.setRequestHeader("X-Auth-Token", $rootScope.authToken);
            }
        },
        processing: true,
        serverSide: true,
        columns: [
            {
                'data': 'image',
                searchable: false,
                orderable: false,
                render: function (data, type, row) {
                    if (!data)
                        data = 'assets/images/product.png';
                    var html = '<div style="width:64px;"> \
                                    <img src="' + data + '" alt="product-img" title="product-img" class="rounded-circle avatar-lg"></img> \
                                </div>';
                    return html;
                }
            },
            {
                'data': 'name',
                render: function (data, type, row) {
                    var html = '<div class="text-wrap" style="max-width: 200px;">' + data + '</div>';
                    return html;
                }
            },
            {
                'data': 'brand',
                'name': 'brands.name',
                render: function (data, type, row) {
                    if (!data)
                        return 'N/A';
                    return data;
                }
            },
            {
                'data': 'no_of_variants'
            },
            {

                'data': 'tags',
                render: function (data, type, row) {
                    var html = '<div class="text-wrap" style="max-width: 200px;">' + data + '</div>';
                    return html;
                }
            },
            {
                'data': 'status',
                render: function (data, type, row) {
                    labelName = data == 1 ? 'Active' : 'Draft';
                    className = data == 1 ? 'bg-success' : 'bg-warning';
                    return '<span style="font-size: 12px;" class="p-1 badge rounded-pill ' + className + '" >' + labelName + '</span>';
                }
            }
        ],
        order: [[1, 'asc']],
        "language": {
            "paginate": {
                "previous": "<i class='mdi mdi-chevron-left'>",
                "next": "<i class='mdi mdi-chevron-right'>"
            }
        },
        "drawCallback": function () {
            $('.dataTables_paginate > .pagination').addClass('pagination-rounded');
        }
    });

    setTimeout(function () {
        $('#products-table tbody').on('click', 'tr', function () {
            var data = productsDt.row(this).data();
            window.location.href = '#!/product/' + data.id;
        });
    }, 500);
});