app.controller('shipmentsController', function shipmentsController($scope, $http, $cookies, $window, $location, $rootScope, $filter, $compile) {
    $rootScope.pageTitle = 'Shipments';
    $rootScope.selectedMenu = 'viewShipments';
    $scope.orderProductsSelected = {};
    $scope.statusFilter = '0';
    $scope.shipmentsDt = null;

    $scope.loadDt = function () {
        $scope.shipmentsDt = $('#shipments-table').DataTable({
            ajax: {
                'url': $rootScope.apiPath + '/shipments/getShipments?statusFilter=' + $scope.statusFilter,
                'type': 'GET',
                beforeSend: function (request) {
                    request.setRequestHeader("X-Auth-Token", $rootScope.authToken);
                }
            },
            processing: true,
            serverSide: true,
            columns: [
                {
                    'data': 'product_image',
                    searchable: false,
                    orderable: false,
                    render: function (data, type, row) {
                        if (!data)
                            data = 'assets/images/product.png';
                        var html = '<div style="width:64px;"> \
                                        <img src="' + data + '" alt="product-img" title="product-img" class="rounded-circle avatar-lg"></img> \
                                    </div>';
                        return html;
                    }
                },
                {
                    'data': 'sku',
                    'name': 'order_products.sku',
                    searchable: true,
                    orderable: false,
                    visible: false
                },
                {
                    'data': 'name',
                    searchable: true,
                    orderable: true,
                    render: function (data, type, row) {
                        var html = '<div class="text-wrap" style="max-width: 200px;"><div>' + data + '</div><a href="#!/product/' + row.id + '"><small>' + (row.sku || '-') + '</small></a></div>';
                        return html;
                    }
                },
                {
                    'data': 'shipment_reference',
                    'name': 'shipments.reference',
                    searchable: true,
                    orderable: true

                },
                {
                    'data': 'order_no',
                    'name': 'orders.name',
                    searchable: true,
                    orderable: true

                },
                {
                    'data': 'shipment_created',
                    name: 'shipments.created_at',
                    searchable: false,
                    orderable: true,
                    render: function (data, type, row) {
                        return moment(data, 'YYYY-MM-DD hh:mm:ss').format('YYYY-MM-DD');
                    }
                },
                {
                    'data': 'quantity',
                    searchable: false,
                    orderable: true,
                    render: function (data, type, row) {
                        return $filter('number')(data, 0) + (data > 1 ? ' Units' : ' Unit');
                    }
                },
                {
                    'data': 'cost',
                    searchable: false,
                    orderable: true,
                    render: function (data, type, row) {
                        return 'PKR ' + $filter('number')(data, 2);
                    }
                },
                {
                    'data': 'price',
                    searchable: false,
                    orderable: true,
                    render: function (data, type, row) {
                        return 'PKR ' + $filter('number')(data, 2);
                    }
                },
                {
                    'data': 'discount_per_item',
                    searchable: false,
                    orderable: true,
                    render: function (data, type, row) {
                        return 'PKR ' + $filter('number')(data, 2);
                    }
                },
                {
                    'data': 'line_total',
                    searchable: false,
                    orderable: true,
                    render: function (data, type, row) {
                        return 'PKR ' + $filter('number')(data, 2);
                    }
                },
                {
                    'data': 'status',
                    'name': 'shipment_products.status',
                    searchable: false,
                    orderable: true,
                    render: function (data, type, row) {
                        return '<span style="font-size: 12px;" class="p-1 badge rounded-pill bg-' + row.statusDetails.color + '" >' + row.statusDetails.text + '</span>';
                    }
                }
            ],
            order: [[1, 'asc']],
            "language": {
                "paginate": {
                    "previous": "<i class='mdi mdi-chevron-left'>",
                    "next": "<i class='mdi mdi-chevron-right'>"
                }
            },
            "drawCallback": function () {
                $('.dataTables_paginate > .pagination').addClass('pagination-rounded');
            }
        });

        setTimeout(function () {
            $('#shipments-table tbody').on('click', 'tr', function () {
                var data = $scope.shipmentsDt.row(this).data();
                window.location.href = '#!/shipment/' + data.id;
            });
        }, 500);

        $(".dataTables_length select").addClass('form-select form-select-sm');
        $(".dataTables_length select").removeClass('custom-select custom-select-sm');
        $(".dataTables_length label").addClass('form-label');
    }

    $scope.$watch('statusFilter', function () {
        if ($scope.shipmentsDt)
            $scope.shipmentsDt.destroy();
        $scope.loadDt();
    });
});