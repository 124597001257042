app.controller('dashboardController', function dashboardController($scope, $http, $cookies, $window, $location, $rootScope, $filter) {
    console.log('dashboard page');
    $rootScope.pageTitle = 'Dashboard';
    $rootScope.selectedMenu = 'dashboard';
    // $.NotificationApp.send("Oh snap!", "Change a few things up and try submitting again.", 'top-right', '#bf441d', 'danger');

    function onDateRangeChange(start, end) {
        $('.dashboardDateRange span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
    }

    setTimeout(function () {
        var start = moment().startOf('month');
        var end = moment().endOf('month');

        $('.dashboardDateRange').daterangepicker({
            startDate: start,
            endDate: end,
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }
        }, onDateRangeChange);

        onDateRangeChange(start, end);
    }, 500);

    $http({
        method: 'GET',
        url: $rootScope.apiPath + '/dashboard/getDashboardData',
        headers: { 'X-Auth-Token': $rootScope.authToken }
    }).then(function successCallback(response) {
        $scope.totalOrders = response.data.totalOrders;
        $scope.totalItems = response.data.totalItems;
        $scope.totalSales = response.data.totalSales;
        $scope.totalProductsListed = response.data.totalProductsListed;
    }, function errorCallback(response) {
        console.log(response);
    });
});