app.config(function ($routeProvider) {
    $routeProvider
        .when('/', {
            templateUrl: 'dashboard.html?v=' + _version,
            controller: 'dashboardController'
        }).when('/orders', {
            templateUrl: 'orders.html?v=' + _version,
            controller: 'ordersController'
        }).when('/products', {
            templateUrl: 'products.html?v=' + _version,
            controller: 'productsController'
        }).when('/product/:id', {
            templateUrl: 'product.html?v=' + _version,
            controller: 'productController'
        }).when('/add-product', {
            templateUrl: 'add-product.html?v=' + _version,
            controller: 'addProductController'
        }).when('/shipments', {
            templateUrl: 'shipments.html?v=' + _version,
            controller: 'shipmentsController'
        }).when('/shipment/:id', {
            templateUrl: 'shipment.html?v=' + _version,
            controller: 'shipmentController'
        })
});